.news {
	&__list{
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		justify-content: space-around;
		flex-flow: row wrap;
	}
	&__item{
		display: block;
		width: 100%;
		text-align: center;
		& + & {
			padding: 10px 0 0;
			border-top: 2px solid #000; 
		}
	}
	&__link{
		img{
			display: block;
		}
	}
	&__title{
		font-size: 16px;
		margin: 0;
	}
	&__date{
		margin: 0 0 20px;
		font-size: 11px;
	}
	&__description{
		padding-right: 5px;
		text-align: left;
	}
	&__media {
		float: left;
		margin: 0 10px 10px 0;
		max-width: 50%;
	}
}

@media screen and (max-width: 451px) {
	.news {
		&__description{
			padding-right: 0;
			width: 90%;
			text-align: justify;
			display: block;
			margin: 10px auto;
		}
		&__media {
			display: block;
			float: none;
			max-width: 90%;
			margin: 0 auto;
		}
	}
}