@import "../mixins";

.popup {
	display: flex;
	position: absolute;
	flex-flow: row wrap;
	top:0;
	left: 0;
	min-height: 100%;
	width: 100%;
	background: rgba(0,0,0,0.9);
	padding: 20px;
	border-radius: 4px;
	overflow: hidden;
	&__anotation{
		position: relative;
		width: 100%;
		padding-top: 100%;
		z-index: 2;
		display: block;
		overflow: hidden;
		opacity: 0;
		transition: all 500ms;
		cursor: pointer;
	}
	&__anotation-test{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $main-color;
		font-size: 14px;
		width: 100%;
		margin: 0;
		text-shadow: 2px 2px 2px $info-color;
	}
	&__left{
		width: 30%;
		position: relative;
		z-index: 1;
	}
	&__coverLink{
		display: block;
		width: 100%;
		max-width: 100%;
		cursor: pointer;
		& > img{
			display: block;
			width: 100%;
			position: relative;
			top: 0;
			left: 0;
			transition: all 200ms;
		}
	}
	&__wrapper{
		display: block;
		width: 60%;
		text-align: left;
	}
	&__title{
		margin: 0 0 10px 0;
		padding-left: 20px;
		a {
			color: $white-color;
		}
	}
	&__secondary-list{
		list-style-type: decimal-leading-zero;
		color: $white-color;
		font-size: 14px;
		font-weight: 400;
	}
	&__close{
		display: block;
		width: 40px;
		height: 40px;
		cursor: pointer;
		position: absolute;
		color: $white-color;
		top: 20px;
		right: 20px;
		font-size: 40px;
		line-height: 40px;
		z-index: 10;
	}
}

@media screen and (max-width: 840px) {
	.popup {
		flex-flow: column wrap;
		padding: 0px;
		&__left {
			padding: 20px 30px;
			width: 90vw;
			margin-top: 20px;
		}
		&__wrapper{
			width: 90vw;
			width: 100%;
			padding: 0 30px;
			margin-top: 0px;
		}
		&__title{
			padding: 0px;
		}
		&__secondary-list{
			padding-left: 20px;
		}
	}
}