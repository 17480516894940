@import url('https://fonts.googleapis.com/css?family=Permanent+Marker|Roboto+Condensed');
@import "./mixins";

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Roboto Condensed', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	background: #0a0000;
	color: $main-color;
	font-size: $small-font;
	@extend .custom-scroll;
}
#animation {
	background: radial-gradient(ellipse at top, #e5c7c8 0%, #ff0000 0%, transparent 65%, transparent 86%, transparent 72%);
	z-index: 0;
	opacity: 0;
	border-radius: 10%;
	transition: all 1s;
	&.active {
		opacity: 1;
	}
}
.custom-scroll{
	&::-webkit-scrollbar-track {
		border: 1px solid $link-color;
		background-color: $main-color;
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: $main-color;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $link-color;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

* {
	box-sizing: border-box;
}

a {
	&:link,
	&:hover,
	&:visited,
	&:hover,
	&:active {
		color: $main-color;
	}
	color: $main-color;
	font-weight: bold;
}
a:hover{
	text-decoration: none;
}

#root {
	position: relative;
	z-index: 2;
	background-color: $dark-red;
	//background-color: $back-color;
	//background-image: url('./image/background.jpg');
	background-size: contain;
	//animation: rain 200ms infinite linear;
}

.wrapper{
	position: relative;
	//&:before{
	//	content: '';
	//	position: fixed;
	//	top: 0;
	//	left: 0;
	//	bottom: 0;
	//	width: 100%;
	//	height: 100%;
	//	right: 0;
	//	background: url('./image/goast.png');
	//	background-size: auto;
	//	opacity: 0.1;
	//	background-repeat: no-repeat;
	//	animation: glitch-anim 10s infinite;
	//	z-index: 3;
	//	pointer-events: none;
	//	background-position: 50% 0;
	&:after{
		position: relative;
		content: '';
		width: 100vw;
		height: 100%;
		top: 0;
		left: 0;
		position: fixed;
		background-image: url('./image/tv.png');
		animation: noise 2000ms infinite linear;
		background-position: 0 0;
		pointer-events: none;
		opacity: 0;
		z-index: 2;
		transition: opacity 1s;
	}
	&.active:after{
		opacity: 0.6;
	}
}

@keyframes glitch-anim {
  $steps: 100;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(400)+vw, 100vw, random(100)+vh, 0);
    }
  }
}

@-webkit-keyframes glitch-anim {
	$steps: 100;
	@for $i from 0 through $steps {
		#{percentage($i*(1/$steps))} {
			clip: rect(random(400)+vw, 100vw, random(100)+vh, 0);
		}
	}
}

#animation{
	position: fixed;
	top: -134vw;
	left: 50%;
	transform: translate(-50%, -50vw);
	z-index: 1;
	transition-property: top;
	transition-duration: 2s;
	&.disactive {
		max-width: 465px;
		top: 50vh;
		left: 50%;
		transform: translate(-50%, -50%);
		#gameboy{
			max-width: 100%;
			width: 50vw;
			max-height: 80vh;
			margin-top: -2%;
		}
	}
}

#gameboy{
	position: relative;
	display: block;
	width: 800vw;
	max-height: 4000vh;
	transition-property: width;
	transition-duration: 2s;
	fill: none !important;
}

@keyframes glitch {
  0% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  1% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  2% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  3% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  4% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  5% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  6% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  7% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  8% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  9% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  10% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  11% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  12% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  13% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  14% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  15% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  16% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  17% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  18% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  19% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  20% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  21% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  22% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  23% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  24% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  25% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  26% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  27% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  28% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  29% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  30% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  31% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  32% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  33% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  34% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  35% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  36% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  37% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  38% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  39% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  40% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  41% { text-shadow: 45px 0 0 #0c33f5, -45px 0 0 lime;}
  42% { text-shadow: 0 0 0 #0c33f5, 0 0 0 lime;}
  43% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  44% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  45% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  46% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  47% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  48% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  49% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  50% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  51% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  52% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  53% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  54% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  55% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  56% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  57% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  58% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  59% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  60% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  61% { text-shadow: 30px 0 0 red, -30px 0 0 lime;}
  62% { text-shadow: 0 0 0 red, 0 0 0 lime;}
  63% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
  64% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
  65% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
  66% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
  67% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  68% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  69% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  70% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  71% { text-shadow: 50px 0 0 red, -50px 0 0 #0c33f5;}
  72% { text-shadow: 0 0 0 red, 0 0 0 #0c33f5;}
  73% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  74% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  75% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  76% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
  77% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  78% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  79% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  80% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
  81% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  82% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  83% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  84% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  85% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
  86% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  87% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  88% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  89% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  90% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
  91% { text-shadow: 60px 0 0 lime, -60px 0 0 #0c33f5;}
  92% { text-shadow: 0 0 0 lime, 0 0 0 #0c33f5;}
  92% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  93% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  94% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  95% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
  96% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  97% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  98% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  99% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
  100% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
}

@-webkit-keyframes glitch {
	0% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	1% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	2% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	3% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	4% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	5% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	6% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	7% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	8% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	9% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	10% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	11% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	12% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	13% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	14% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	15% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	16% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	17% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	18% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	19% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	20% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	21% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	22% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	23% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	24% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	25% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	26% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	27% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	28% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	29% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	30% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	31% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	32% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	33% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	34% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	35% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	36% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	37% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	38% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	39% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	40% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	41% { text-shadow: 45px 0 0 #0c33f5, -45px 0 0 lime;}
	42% { text-shadow: 0 0 0 #0c33f5, 0 0 0 lime;}
	43% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	44% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	45% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	46% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	47% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	48% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	49% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	50% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	51% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	52% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	53% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	54% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	55% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	56% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	57% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	58% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	59% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	60% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	61% { text-shadow: 30px 0 0 red, -30px 0 0 lime;}
	62% { text-shadow: 0 0 0 red, 0 0 0 lime;}
	63% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
	64% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
	65% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
	66% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5;}
	67% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	68% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	69% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	70% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	71% { text-shadow: 50px 0 0 red, -50px 0 0 #0c33f5;}
	72% { text-shadow: 0 0 0 red, 0 0 0 #0c33f5;}
	73% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	74% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	75% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	76% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5;}
	77% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	78% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	79% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	80% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5;}
	81% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	82% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	83% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	84% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	85% { text-shadow: 1px 0 0 red, -1px 0 0 lime;}
	86% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	87% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	88% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	89% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	90% { text-shadow: -3px 0 0 red, 3px 0 0 lime;}
	91% { text-shadow: 60px 0 0 lime, -60px 0 0 #0c33f5;}
	92% { text-shadow: 0 0 0 lime, 0 0 0 #0c33f5;}
	92% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	93% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	94% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	95% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime;}
	96% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	97% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	98% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	99% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
	100% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime;}
}

.App {
	text-align: center;
	filter: contrast(1.2);
	image-rendering: initial;
	image-rendering: -moz-crisp-edges;
	-ms-interpolation-mode: nearest-neighbor;
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&__header {
		position: relative;
		width: 100%;
		font-size: 8em;
		line-height: 1.2;
		text-shadow: 2px 2px 10px $link-color;
		color: $info-color;
		font-family: 'Permanent Marker', cursive;
		margin-bottom: 10px;
		animation: glitch 2s steps(20) infinite;
	}
	&__wrapper {
		position: relative;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		align-content: stretch;
		justify-content: space-between;
		font-size: $medium-font;
		font-weight: 600;
		width: 90vw;
		max-width: 1100px;
		margin: 0 auto;

	}
	&__wrapper-cell{
		position: relative;
		display: flex;
		width: 49%;
		flex-flow: column wrap;
		justify-content: space-around;
		z-index: 1;
		.news {
			@extend .custom-scroll;
			max-height: 346px;
			overflow: auto;
		}
		.events {
			@extend .custom-scroll;
			max-height: 486px;
			overflow: auto;
		}
	}
	&__wrapper-row{
		width: 100%;
		z-index: 2;
		position: relative;
	}
	&__footer{
		text-align: center;
		margin: 10px 0;
		font-weight: bold;
	}
	&__hand-rotate {
		transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		display: inline-block;
	}
}

#gameboy_screen{
	fill: none;
	stroke: none !important;
	&.active{
		fill: $main-color;
	}
}
.container{
	width: 100%;
	//background: $main-color;
	margin: 0 0 10px;
	padding: 10px 30px;
	border: 2px dashed $info-color;
	flex: 0 1 calc(50% - 10px);
	position: relative;
	&_news {
		min-height: 382px;
	}
	&__header{
		margin: 0 0 20px;
		padding: 0;
		text-align: center;
		font-size: calc(10px + 2vmin);
		background: $info-color;
		color: $white-color;
	}
	&__text {
		text-indent: 1.5em;
		text-align: justify;
		margin: 0;
	}
	&__sub-header{
		margin: 0 0 20px;
		background: $info-color;
		color: $white-color;
		padding: 2px 0;
	}
}

.contact-list{
	list-style-type: none;
	display: flex;
	justify-content: space-around;
	flex-flow: row wrap;
	padding: 0;
	margin: 25px 0 0 0;
	&__item{
		display: block;
	}
	&__link{
		font-size: 0;
		width: 60px;
		height: 60px;
		display: block;
		stroke-width: 5px;
		margin-bottom: 10px;
		transition: transform 200ms;
		&:hover{
			transform: scale(1.2);
		}
		&>svg{
			fill:none;
			stroke:$link-color;
			stroke-miterlimit:10;
		}
	}
}

.discography{
	&__list{
		padding: 0;
		margin: 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-evenly;
		list-style-type: none;
		width: 100%;
		position: relative;
		margin-bottom: 40px;
		perspective: 100vw;
		perspective-origin:125% 0;

		//-webkit-box-reflect: below 7px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.8, transparent), to(#ff0000));
	}
	&__item{
		display: flex;
		margin: 0;
		padding: 0;
		background: $info-color;
		width: 14%;
		transition: all 200ms;
		z-index: 1;
		transform: matrix3d(0.4, -0.02, -0.5, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 0, 0, 1);
		transform-style: flat;
		overflow: hidden;
		position: relative;
		box-shadow: 2px 2px 5px #000;
		& + & {
			margin-left: -15%;
			//margin-top: -92%;
		}
		&_active,
		&:hover{
			border-radius: 0;
			z-index: 2;
			transform: matrix3d( 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
			.discography__anotation{
				background: rgba(0,0,0,0.5);
				opacity: 1;
			}
			.discography__coverLink > img{
				filter: grayscale(0);
			}
		}
	}
	&__anotation{
		position: relative;
		width: 100%;
		padding-top: 100%;
		z-index: 2;
		display: block;
		overflow: hidden;
		opacity: 0;
		transition: all 500ms;
		cursor: pointer;
	}
	&__anotation-test{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $white-color;
		font-size: 14px;
		width: 100%;
		margin: 0;
		text-shadow: 2px 2px 2px $info-color;
	}
	&__coverLink{
		width: 100%;
		position: relative;
		z-index: 1;
		cursor: pointer;
		& > img{
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			filter: grayscale(1);
			transition: all 200ms;
			height: 100%;
		}
	}
	&__wrapper{
		display: none;
		width: 0%;
		text-align: left;
	}
	&__title{
		margin: 0 0 10px 0;
		padding-left: 40px;
	}
	&__info{

	}
	&__secondary-list{
		list-style-type: decimal-leading-zero;
	}
	&__secondary-item{

	}
}

// @for $i from 1 through 30 {
// 	.discography__item:nth-of-type(#{$i}) {
// 		z-index: #{30 - $i};
// 		&:hover,&.discography__item_active{
// 			z-index: 31;
// 		}
// 	}
// }

@keyframes noise {
	0% {
		background-position: 0px 1000px;
	}
	50% {
		background-position: -1000px;
	}
	100% {
		background-position: 100px 0px;
	}
}

@-webkit-keyframes noise {
	0% {
		background-position: 0px 1000px;
	}
	50% {
		background-position: -1000px;
	}
	100% {
		background-position: 100px 0px;
	}
}

@keyframes rain {
	0% {
		background-position: 0px 0;
	}
	50% {
		background-position: 0 313px;
	}
	100% {
		background-position: 0 626px;
	}
}

@-webkit-keyframes rain {
	0% {
		background-position: 0px 0;
	}
	50% {
		background-position: 0 313px;
	}
	100% {
		background-position: 0 626px;
	}
}

@-moz-document url-prefix() {
	.discography{
		&__list{

		}
		&__anotation-test{
			font-size: 10px;
		}
		&__item, &__item:hover{
			transform: none;
			width: 15%;
			margin-bottom: 5px;
			& + & {
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 840px) {
	.container {
		width: 90vw;
	}
	.App__wrapper-cell{
		display: flex;
		width: 100%;
		flex-flow: column wrap;
		justify-content: space-around;
	}
	.wrapper{
		position: relative;
		// &:after{
		// 	//display: none;
		// 	background: rgba(0,0,0,0.3);
		// 	animation-play-state: paused;
		// }
	}
	.App__header {
		font-size: 5em;
	}
	.discography{
		&__list{

		}
		&__anotation-test{
			font-size: 10px;
		}
		&__item, &__item:hover{
			transform: none;
			width: 24%;
			margin-bottom: 5px;
			& + & {
				margin-left: 0;
			}
		}
	}
}

.bandcamp-layer{
	padding-left: 20px;
	margin-bottom: 20px;
	iframe {
		border: 0; width: 100%; height: 120px;
	}
}

@media screen and (max-width: 1400px) {
	#animation.disactive #gameboy {
		margin: inherit;
		margin-top: -2%;
	}
}

@media screen and (max-width: 840px) {
	.bandcamp-layer {
		padding-left: 0;
	}
}
@media screen and (max-width: 641px) {
	.App__header {
		font-size: 3em;
	}
}
