.kits {
	&__list{
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		justify-content: space-around;
		flex-flow: row wrap;
	}
	&__item{
		margin: 10px;
	}
	&__link{
		display: block;
		font-size: 14px;
		position: relative;
		width: 100%;
	}
}